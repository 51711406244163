import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import BookDemoPartnerForm from "../components/book_demo_partner_form"
import landlord_header from "../images/landlord_header.png"

import wave from "../images/wave.svg"

const BookDemoPartners = () => (
  <Layout>
    <SEO
      title="Book a Demo"
      description="You have to see what Flow can do for you"
      image={landlord_header}
    />

    <section className="md:self-center container mx-auto bg-white w-full mt-10 rounded-xl relative flex flex-col md:flex-row md:mt-12">
      <div className="w-full md:w-1/2 px-10">
        <h2 className="text-2xl md:text-4xl text-left font-bold text-gray-700 leading-tight md:mt-0">
          You have to see what <span className="wrap">Flow</span> can do for you
        </h2>

        <BookDemoPartnerForm />
      </div>

      <div className="w-full md:w-1/2 bg-gray-100 rounded-xl p-10">
        <p className="text-md md:text-xl text-center md:text-left font-bold text-gray-700 max-w-md mt-4 md:ml-16">
          You get a one on one view of:
        </p>

        <div className="w-full flex text-left mt-10">
          <div className="flex inline-block w-10 h-10 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-6">
            <i className="w-full self-center fas fa-home"></i>
          </div>
          <div>
            <h3 className="font-bold text-gray-700 ">Getting in front of buyers and renters</h3>
            <p className="font-light text-sm text-gray-400 mr-4">
            How Flow places your brand in front of our audiences of buyers and renters of property
            </p>
          </div>
        </div>

        <div className="w-full flex text-left mt-5">
          <div className="flex inline-block w-10 h-10 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-6">
            <i className="w-full self-center fas fa-user-check"></i>
          </div>
          <div>
            <h3 className="font-bold text-gray-700 ">
            The unique placements on Flow
            </h3>
            <p className="font-light text-sm text-gray-400 mr-4">
            Demo of your partner page, enquiry confirmation email/sms and Facebook placement
            </p>
          </div>
        </div>

        <div className="w-full flex text-left mt-5">
          <div className="flex inline-block w-10 h-10 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-6">
            <i className="w-full self-center fas fa-chart-line"></i>
          </div>
          <div>
            <h3 className="font-bold text-gray-700 ">Integrating and tracking to your business</h3>
            <p className="font-light text-sm text-gray-400 mr-4">
            How we can integration to your e-comm site or call center to ensure we're driving pure ROI
            </p>
          </div>
        </div>

      </div>
    </section>

    <img className="w-full mt-10" src={wave} alt="" />

    <Footer />
  </Layout>
)

export default BookDemoPartners
