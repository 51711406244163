import React from "react"
import { useUrlSearchParams } from "use-url-search-params";
import Cookies from 'universal-cookie';

function handleClick(e) {
  console.log('Test');

  const currentForm = document.getElementById("bookDemoForm")

  if (currentForm.checkValidity() == true) {
    e.target.disabled = true;
    e.target.innerText = "Submitting...";
    currentForm.submit();
  }
}

const BookDemoPartnerForm = () => {

  const cookies = new Cookies();

  return (
    <form
      action="https://getform.io/f/74f88a7a-be0e-45c0-a054-ff49e0e375af"
      method="POST"
      id="bookDemoForm"
    >
      <input
        name="Name"
        type="text"
        placeholder="Name"
        className="input-alt w-full mt-10"
        required
      />
      <input
        name="Surname"
        type="text"
        placeholder="Surname"
        className="input-alt w-full mt-2"
        required
      />
      <input
        name="EmailDemoFrom"
        type="text"
        placeholder="Email"
        className="input-alt w-full mt-2"
        required
      />
      <input
        name="ContactNumber"
        type="text"
        placeholder="Contact Number"
        className="input-alt w-full mt-2"
        required
        inputMode="numeric" pattern="[0-9]{10}"
      />
      <input
        name="CompanyName"
        type="text"
        placeholder="Company Name"
        className="input-alt w-full mt-2"
        required
      />

      <input placeholder="utm_source" type="text" name="utm_source" className="hidden" value={cookies.get('utm_source')}></input>
      <input placeholder="utm_medium" type="text" name="utm_medium" className="hidden" value={cookies.get('utm_medium')}></input>
      <input placeholder="utm_campaign" type="text" name="utm_campaign" className="hidden" value={cookies.get('utm_campaign')}></input>
      <input placeholder="utm_content" type="text" name="utm_content" className="hidden" value={cookies.get('utm_content')}></input>

      <p className="text-xxs text-gray-500 mt-4">By submitting I agree to allow Flow to collect, store and process my data, as per the <a href="/privacypolicy" target="_blank" className="text-orange-500 underline">Privacy Policy</a></p>
      
      <button onClick={handleClick} type="submit" className="btn-primary mt-2 w-full">
        Book a demo
      </button>
    </form>
  )
}

export default BookDemoPartnerForm
